$enable-flex: false !default; // switch from a floating grid system to a flex grid system

// ***********************************************
// Layout, Metrics (and responsiveness)
// ***********************************************
$grid-columns: 12;

$space-thin-v: 15px;
$space-thin-h: 15px;
$space-medium-v: 30px;
$space-medium-h: 30px;
$space-large-v: 6em;
$space-large-h: 60px;

$grid: 30px;
$grid-gutter-width: $grid;
$half-grid: ceil($grid / 2);
$thin-grid: $space-thin-h;
$grid-vertical: $space-medium-v;
$mobile-grid: 20px;
$page-outer-grid: 35px;
$page-max-width: 1100px;
$page-max-width-tile-pages: $page-max-width; // deprecated variable?

$sidebar-first-width: 180px + $grid;
$sidebar-second-width: 220px + $grid;

// Tile Grid & Widths
$tile-width: 357px;
$tile-grid-space:15px;
$tile-breakpoint-offset:20px;

// ***********************************************
// Breakpoints
// ***********************************************

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$small-breakpoint-value:  544px;
$medium-breakpoint-value: 768px;
$large-breakpoint-value:  992px;
$xlarge-breakpoint-value: $page-max-width;
$xxlarge-breakpoint-value: 1420px;


$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: $small-breakpoint-value,
  // Medium screen / tablet
  md: $medium-breakpoint-value,
  // Large screen / desktop
  lg: $large-breakpoint-value,
  // Extra large screen / wide desktop
  xl: $xlarge-breakpoint-value,
  // Extra Extra large screen / wide desktop
  xxl: $xxlarge-breakpoint-value
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// TODO: Containers currently not implemented in our theme ..

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px
) !default;

// Further custom Breakpoint definitions
@if ($layout-tile-grid-active == true){
  // Tile layout in use
  $small-breakpoint-value:  $tile-width + $tile-breakpoint-offset  !default;
  $medium-breakpoint-value: ($tile-width * 2) + $tile-grid-space + $tile-breakpoint-offset !default;
  $large-breakpoint-value:  ($tile-width * 3) + ($tile-grid-space * 2) + $tile-breakpoint-offset !default;
  $xlarge-breakpoint-value: ($tile-width * 4) + ($tile-grid-space * 3) + $tile-breakpoint-offset !default;
  $xxlarge-breakpoint-value: ($tile-width * 5) + ($tile-grid-space * 4) + $tile-breakpoint-offset !default;
}

$small-min-breakpoint:  $small-breakpoint-value !default;
$medium-min-breakpoint: $medium-breakpoint-value !default;
$large-min-breakpoint:  $large-breakpoint-value !default;
$xlarge-min-breakpoint: $xlarge-breakpoint-value !default;
$xxlarge-min-breakpoint: $xxlarge-breakpoint-value !default;

$small-max-breakpoint:  $medium-breakpoint-value - 1px  !default;
$medium-max-breakpoint: $large-breakpoint-value - 1px !default;
$large-max-breakpoint:  $xlarge-breakpoint-value - 1px !default;
$xlarge-max-breakpoint:  $xxlarge-breakpoint-value - 1px !default;

// Media Queries
$screen: "only screen" !default;

$landscape: "#{$screen} and (orientation: landscape)" !default;
$portrait: "#{$screen} and (orientation: portrait)" !default;

$small-up: "#{$screen} and (min-width:#{$small-min-breakpoint})" !default;
$small-only: "#{$screen} and (max-width: #{$small-max-breakpoint})" !default;

$medium-up: "#{$screen} and (min-width:#{$medium-min-breakpoint})" !default;
$medium-down: "#{$screen} and (max-width:#{$medium-max-breakpoint})" !default;
$medium-only: "#{$screen} and (min-width:#{$medium-min-breakpoint}) and (max-width:#{$medium-max-breakpoint})" !default;

$large-up: "#{$screen} and (min-width:#{$large-min-breakpoint})" !default;
$large-down: "#{$screen} and (max-width:#{$large-max-breakpoint})" !default;
$large-only: "#{$screen} and (min-width:#{$large-min-breakpoint}) and (max-width:#{$large-max-breakpoint})" !default;

$xlarge-up: "#{$screen} and (min-width:#{$xlarge-min-breakpoint})" !default;
$xlarge-down: "#{$screen} and (max-width:#{$xlarge-max-breakpoint})" !default;
$xlarge-only: "#{$screen} and (min-width:#{$xlarge-min-breakpoint}) and (max-width:#{$xlarge-max-breakpoint})" !default;

$xxlarge-up: "#{$screen} and (min-width:#{$xxlarge-min-breakpoint})" !default;
// does not make sens on the largest mq $xlarge-down: "#{$screen} and (max-width:#{$xlarge-max-breakpoint})" !default;
// does not make sens on the largest mq $xlarge-only: "#{$screen} and (min-width:#{$xlarge-min-breakpoint}) and (max-width:#{$xlarge-max-breakpoint})" !default;

// $xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})" !default;
// $xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})" !default;

// -- This one is a bit special. But it simply says "Its width enough to float elements inside content region"
// -- So it fits the following viewport states: large screens with sidebar & Medium screens without sidbar (sidebar already moved to bottom)
// -- TODO: This needs some testing, may not work as expected.
$enough-width: "#{$screen} and (min-width:#{$medium-min-breakpoint})" !default;
